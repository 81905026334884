export default defineNuxtRouteMiddleware((to) => {
  if(process.client){
    const router = useRouter();

    const { publicPages } = useRuntimeConfig().public;
    const { isLogin, $state } = useUserStore();
    const goToHome = () => router.replace("/home");

    if(isLogin()){
      if($state.hasCompany && to.path === "/register/steps"){
        return goToHome();
      }else if($state?.user?.type === 1 && !$state.hasCompany && to.path !== "/register/steps"){
        return router.replace("/register/steps");
      }else if(publicPages.includes(to.path)){
        return goToHome();
      }else if(to.path === "/home/company/services" && !$state.isRegisterService){
        return goToHome();
      }else if(to.path === "/home/company/pendent" && $state?.user?.type !== 2){
        return goToHome();
      }else if(to.path === "/register/steps" && $state?.user?.type !== 1){
        return goToHome();
      }
    }else{
      if(!publicPages.includes(to.path)){
        return router.replace("/");
      }
    }
  }
});